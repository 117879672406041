
import { defineComponent, onMounted, ref, watch } from "vue";
import { PAGE_TITLE_PATH } from "@/presentation/constants/PageTitle";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "module-list",
  setup() {
    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const currentPage = ref(1);
    const pageSize = ref(5);
    const totalItems = ref(0);
    const pageCount = ref(1);

    const moduleList = ref<any[]>([
      {
        id: 1,
        title: "AI Otel Asistanı",
        desc: "AI Otel Asistanı modülü, oteliniz ile ilgili gelen yorumları yapay zeka modeline ileterek onların değerlendirilmesini amaçlar. ",
        to: "/hotelManagementDetail",
      },
      {
        id: 2,
        title: "Anketör",
        desc: "Bu modülle, personellere ürünler ve çeşitli konular hakkında yapay zeka destekli görüntülü ve sesli anketler yapabilirsiniz. ",
        to: "/pollsterDetail",
      },
      {
        id: 3,
        title: "Mülakatör",
        desc: "Bu modülle, işe alım süreçlerini optimize etme ve modernize etme imkanına sahip olabilirsiniz. ",
        to: "/interviewDetail",
      },
      {
        id: 4,
        title: "Örnek Modül",
        desc: "Modulün kendi açıklamasını buraya yazıyoruz.",
        to: "/hotelManagementDetail",
      },
    ]);
    onMounted(async () => {
      setCurrentPageTitle(t("modalList.title"));
    });

    return {
      PAGE_TITLE_PATH,
      currentPage,
      pageCount,
      pageSize,
      isLoading,
      totalItems,
      moduleList,
    };
  },
});
